import React from "react"
import {useStaticQuery, graphql} from "gatsby";
import * as styles from './menu-section.module.scss';
import {GatsbyImage, getImage, withArtDirection} from "gatsby-plugin-image";
import CollapseItems from "../../components/collapse-items/collapse-items";
import NavigationButton from "../../components/navigation-button/navigation-button";
import MenuLegend from "../../components/menu-legend/menu-legend";

export default function MenuSection({categories}) {

    const data = useStaticQuery(graphql`{
  image1: file(relativePath: {eq: "beefteka/menu-picture.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 99, layout: FULL_WIDTH)
    }
  }
  image2: file(relativePath: {eq: "beefteka/menu-picture-mobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 99, layout: FULL_WIDTH)
    }
  }
}
`)

    const images = withArtDirection(getImage(data.image1), [
            {
                media: "(max-width: 750px)",
                image: getImage(data.image2)
            }
        ]
    )
    return <>
        <div className={styles.MenuWrapper}>
            <GatsbyImage
                image={images}
                alt={"Menu Picture"}
                loading="eager"
                className={styles.photoWrapper}
            />
            <div className={styles.topShadow}/>
            <div className={styles.heroTextWrapper}>
                <div className={styles.heroTextInner}>
                    <div className={styles.heroTitle}>Menu</div>
                    <div className={styles.heroSubtitle}>Nasza karta dań i napojów</div>
                </div>
            </div>
        </div>
        <NavigationButton
            wrapperClassName={styles.buttonWrapper}
            buttonTarget="https://cms.beefteka.pl/menuEnglish.pdf"
            buttonText="Click here for English version"
            useAnchor={true}
        />
        <MenuLegend/>
        <CollapseItems itemList={categories}/>
    </>;
}
