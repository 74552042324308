import React from "react";
import Layout from "../components/layout/layout";
import MenuSection from "../page-sections/menu-section/menu-section";
import { graphql } from "gatsby";
import DessertSection from "../page-sections/dessert-section/dessert-section";
import GallerySection from "../page-sections/gallery-section/gallery-section";
import { Helmet } from "react-helmet";

export default function MenuPage({ data }) {
  const categories = data.menuApi.allCategories;

  const orderedCategories = categories.sort((a, b) => a.position - b.position);

  return <>
    <Layout>
      <Helmet>
        <title>Menu - Beefteka</title>
      </Helmet>
      <MenuSection categories={orderedCategories} />
      <DessertSection />
      <GallerySection />
    </Layout>
  </>
}

export const query = graphql`query {
  menuApi {
    allCategories {
      name
      menuEntries {
        name
        description
        position,
        isVegan,
        isGlutenFree,
        isGlutenFreeAvailable,
        isDairyFree,
        prices {
          value
          description
        }
      }
    }
  }
}`;