import VeganIconSVG from "../../svg/icons/vegan.svg";
import GlutenFreeIconSVG from "../../svg/icons/gluten-free.svg";
import DairyFreeIconSVG from "../../svg/icons/no-dairy.svg";
import * as styles from "./menu-legend.module.scss";
import React from "react"
import { Col, Container, Row } from "react-bootstrap";
import VerticalSpacingVerySmallRow from "../vertical-spacing-very-small/vertical-spacing-very-small";

export default function MenuLegend() {
    return <Container fluid>
        <Row noGutters>
            <Col md={{span: 10, offset: 1}}>
                <VerticalSpacingVerySmallRow />
                <div className={styles.legendTitle}>
                    Oznaczenia
                </div>
                <div className={styles.legendWrapper}>
                    <em className={styles.legendEntry}>
                        <VeganIconSVG className={styles.icon} />
                        danie wegetariańskie
                    </em>
                    <em className={styles.legendEntry}>
                        <GlutenFreeIconSVG className={styles.icon} />
                        nie zawiera glutenu
                    </em>
                    <em className={styles.legendEntry}>
                        <DairyFreeIconSVG className={styles.icon} />
                        bez laktozy
                    </em>
                </div>
            </Col>
        </Row>
    </Container>;
}