// extracted by mini-css-extract-plugin
export var collapseButton = "collapse-items-module--collapse-button--1rlSu";
export var down = "collapse-items-module--down--qkYq3";
export var hint = "collapse-items-module--hint--5DO5I";
export var listItems = "collapse-items-module--list-items--kN+JV";
export var menuFile = "collapse-items-module--menuFile--4UD-P";
export var menuFileLoading = "collapse-items-module--menuFileLoading--oJ5OR";
export var menuItems = "collapse-items-module--menuItems--5Q09t";
export var spin = "collapse-items-module--spin--fSIak";
export var spinner = "collapse-items-module--spinner--zGwsT";
export var text = "collapse-items-module--text--dh8XB";
export var up = "collapse-items-module--up--4rKab";